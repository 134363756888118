import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

//Sections
const Login = () => import('@/pages/application/login/Login')
const NewAccountForm = () => import('@/pages/application/login/NewAccountForm')
const ActivationForm = () => import('@/pages/application/login/ActivationForm')
const ForgetForm = () => import('@/pages/application/login/ForgetForm')
const RecoveryForm = () => import('@/pages/application/login/RecoveryForm')

const Map = () => import('@/pages/application/Map')
const Roads = () => import('@/pages/application/Roads')
const Capture = () => import('@/pages/application/Capture')
const Demo = () => import('@/pages/application/Demo')
const BotMap = () => import('@/pages/application/BotMap')

const Users = () => import('@/pages/application/Users')
const Roles = () => import('@/pages/application/Roles')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/login',
      name: 'Login',
      meta: {
        is_public: true,
      },
      component: Login
    },
    {
      path: '/',
      redirect: 'map',
      name: 'Sorteos',
      component: TheContainer,
      children: [
        {
          path: '/bot-map',
          name: 'Mapa de Tickets',
          component: BotMap
        },
        {
          path: '/map',
          name: 'Mapa',
          component: Map
        },
        {
          path: '/demo',
          name: 'Mapa Demo',
          component: Demo
        },
        {
          path: '/roads',
          name: 'Vialidades',
          component: Roads
        },
        {
          path: '/users',
          name: 'Usuarios',
          component: Users
        },
        {
          path: '/roles',
          name: 'Roles',
          component: Roles
        },
        {
          path: '/capture',
          name: 'Captura de Información',
          component: Capture
        },
      ]
    },
    // {
    //   path: '/',
    //   name: 'Welcome',
    //   component: {        
    //     render (c) { return c('router-view') },
    //   },
    //   meta: {
    //     is_public: true,
    //   },
    //   children: [
    //     {
    //       path: '/',
    //       name: 'Landing Page',
    //       component: Landing,
    //       meta: {
    //         is_public: true,
    //       },
    //     },
    //     {
    //       path: '/boletos',
    //       name: 'Página de Boletos',
    //       component: Tickets,
    //       meta: {
    //         is_public: true,
    //       },
    //     },
    //     {
    //       path: '/pagos',
    //       name: 'Página de Pagos',
    //       component: Payments,
    //       meta: {
    //         is_public: true,
    //       },
    //     },
    //     {
    //       path: '/aviso-de-privacidad',
    //       name: 'Aviso de Privacidad',
    //       component: LandingTerms,
    //       meta: {
    //         is_public: true,
    //       },
    //     },
    //     {
    //       path: '/:code',
    //       name: 'Landing Page',
    //       component: Landing,
    //       meta: {
    //         is_public: true,
    //       },
    //     },
    //     {
    //       path: '/:code/pagos',
    //       name: 'Página de Pagos',
    //       component: Payments,
    //       meta: {
    //         is_public: true,
    //       },
    //     },
    //     {
    //       path: '/:code/boletos',
    //       name: 'Página de Boletos',
    //       component: Tickets,
    //       meta: {
    //         is_public: true,
    //       },
    //     },
    //     {
    //       path: '/:code/aviso-de-privacidad',
    //       name: 'Aviso de Privacidad',
    //       component: LandingTerms,
    //       meta: {
    //         is_public: true,
    //       },
    //     },
    //   ]
    // },
    {
      path: '/signup',
      name: 'Registra tu Cuenta',
      meta: {
        is_public: true,
      },
      component: NewAccountForm
    },
    {
      path: '/account/active/:id/:code',
      name: 'Activa tu Cuenta',
      meta: {
        is_public: true,
      },
      component: ActivationForm
    },
    {
      path: '/forget',
      name: '¿Olvidaste tu Contraseña?',
      meta: {
        is_public: true,
      },
      component: ForgetForm
    },
    {
      path: '/account/recover-password/:id/:code',
      name: 'Recupera tu Contraseña',
      meta: {
        is_public: true,
      },
      component: RecoveryForm
    },
  ]
})